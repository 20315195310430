// extracted by mini-css-extract-plugin
export var project_details_banner_header = "projectDetails-module--project_details_banner_header--Y0kKP";
export var project_details_banner_img = "projectDetails-module--project_details_banner_img--GMV6Q";
export var project_details_banner_texts = "projectDetails-module--project_details_banner_texts--xj+p7";
export var project_details_banner_wrapper = "projectDetails-module--project_details_banner_wrapper--aV8gM";
export var project_details_carousel = "projectDetails-module--project_details_carousel--wHhpc";
export var project_details_carousel_image = "projectDetails-module--project_details_carousel_image--jmdZ4";
export var project_details_carousel_wrapper = "projectDetails-module--project_details_carousel_wrapper--YuKzY";
export var project_details_inner_carousel = "projectDetails-module--project_details_inner_carousel--IHteG";
export var project_details_section_details_inner = "projectDetails-module--project_details_section_details_inner--p9vtp";
export var project_details_section_details_inner_text = "projectDetails-module--project_details_section_details_inner_text--W2xgx";
export var project_details_section_details_wrapper = "projectDetails-module--project_details_section_details_wrapper--xJLlk";
export var project_details_section_overview_inner = "projectDetails-module--project_details_section_overview_inner--d2DaF";
export var project_details_section_showcase_inner = "projectDetails-module--project_details_section_showcase_inner--SqeHn";
export var project_details_section_showcase_wrapper = "projectDetails-module--project_details_section_showcase_wrapper--xne3p";
export var project_details_section_team = "projectDetails-module--project_details_section_team--41jR9";
export var project_details_section_team_avatar = "projectDetails-module--project_details_section_team_avatar--GyilI";
export var project_details_section_team_avatar2 = "projectDetails-module--project_details_section_team_avatar2--okl1y";
export var project_details_section_wrapper = "projectDetails-module--project_details_section_wrapper--HHncp";
export var project_details_showcase_video_img = "projectDetails-module--project_details_showcase_video_img--nUKeS";
export var project_details_showcase_video_wrapper = "projectDetails-module--project_details_showcase_video_wrapper--5J3O3";
export var project_showcase_card_wrapper = "projectDetails-module--project_showcase_card_wrapper--wwTW+";
export var project_showcase_carousel = "projectDetails-module--project_showcase_carousel--ICUlK";
export var project_showcase_carousel_image = "projectDetails-module--project_showcase_carousel_image--gnTVv";
export var project_showcase_carousel_image_content = "projectDetails-module--project_showcase_carousel_image_content--vhhn3";
export var project_showcase_inner_carousel = "projectDetails-module--project_showcase_inner_carousel--2Zzkq";
export var project_showcase_link = "projectDetails-module--project_showcase_link--Qqp8j";